import React, { Component } from 'react'
import { Alert, Button, Form, Row, Col, Card } from 'react-bootstrap'


const CloseButton = ({ closeToast }) => (
  <button className="Toastify__close-button" type="button" aria-label="close"
    onClick={closeToast}>&times;</button>
)

class Notifications extends Component {
  
    state = {
      // Alerts
      //

      show : this.props.show,
      text : this.props.content,
      darkAlerts: [
        'dark-primary',
        'dark-secondary',
        'dark-success',
        'dark-danger',
        'dark-warning',
        'dark-info',
        'dark-dark'
      ]
    }
  
  

  dismiss(variant) {
    this.setState({
      alerts: this.state.alerts.filter(_variant => _variant !== variant)
    })
  }

  dismissDark(variant) {
    this.setState({
      darkAlerts: this.state.darkAlerts.filter(_variant => _variant !== variant)
    })
  }

  prevent(e) {
    e.preventDefault()
  }

  render() {
    const isRTL = document.documentElement.getAttribute('dir') === 'rtl'

    return (
      <div>
      
      <Alert dismissible key={'warning'} variant={'warning'} show={this.state.show} onClose={() => {
        this.setState({show : false});
        this.props.show();
      }}>
      {this.state.text}
      </Alert>


      </div>
    )
  }
}

export default Notifications
