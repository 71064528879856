import React from 'react'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import Loader from './shared/Loader'
import LayoutBlank from './shared/layouts/LayoutBlank'
// Layouts
import Layout1 from './shared/layouts/Layout1'

// Lazy load component
const lazy = (cb) => loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> })

// ---
// Default application layout

export const DefaultLayout = Layout1

// ---
// Document title template

export const titleTemplate = '%s - AN'

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export const defaultRoute = '/'
export const routes = [
  {
    path: '/',
    component: lazy(() => import('./components/Login')),
    layout: LayoutBlank
  }, {
    path: '/calendar',
    component: lazy(() => import('./components/Calendar_page'))
  }
  , {
    path: '/page-2',
    component: lazy(() => import('./components/Page2'))
  }
  , {
    path: '/payment-history',
    component: lazy(() => import('./components/payment_history'))
  }, {
    path: '/member-join',
    component: lazy(() => import('./components/Member_join'))
  },  {
    path: '/member-list',
    component: lazy(() => import('./components/Member_list'))
  }, {
    path: '/member-unpaid',
    component: lazy(() => import('./components/Member_unpaid'))
  },{
    path: '/em-list',
    component: lazy(() => import('./components/Em_list'))
  }, {
    path: '/em-join',
    component: lazy(() => import('./components/Em_join'))
  }
  , {
    path: '/em-working',
    component: lazy(() => import('./components/Em_working'))
  }
  , {
    path: '/em-calc',
    component: lazy(() => import('./components/Em_calc'))
  },
  {
    path: '/set_reservation',
    component: lazy(() => import('./components/Setting1'))
  }
  ,
  {
    path: '/set_product',
    component: lazy(() => import('./components/Setting2'))
  }
  ,
  {
    path: '/set_prepayment',
    component: lazy(() => import('./components/Setting3'))
  }
  ,
  {
    path: '/set_ticket',
    component: lazy(() => import('./components/Setting4'))
  }
  ,
  {
    path: '/member_set',
    component: lazy(() => import('./components/Setting5'))
  }
  ,
  {
    path: '/point-history',
    component: lazy(() => import('./components/Point_list'))
  }
  ,
  {
    path: '/prepoint-history',
    component: lazy(() => import('./components/Prepoint_list'))
  }
  ,
  {
    path: '/pass-history',
    component: lazy(() => import('./components/Pass_list'))
  }
  ,
  {
    path: '/inventory-history',
    component: lazy(() => import('./components/Inventory_list'))
  }
  ,
  {
    path: '/gallery-box',
    component: lazy(() => import('./components/Gallerybox'))
  }
  ,
  {
    path: '/sms',
    component: lazy(() => import('./components/Sms'))
  }
  ,
  {
    path: '/stat-period',
    component: lazy(() => import('./components/Stat_period'))
  }
  ,

  {
    path: '/em-period',
    component: lazy(() => import('./components/Em_period'))
  }
  ,

  {
    path: '/join',
    component: lazy(() => import('./components/Join')),
    layout: LayoutBlank
  }
  ,
  {
    path: '/mypage',
    component: lazy(() => import('./components/Mypage'))
  }
  ,
  {
    path: '/msg',
    component: lazy(() => import('./components/Message'))
  }  ,
  {
    path: '/message',
    component: lazy(() => import('./components/Message_user')),
  }
  ,
  {
    path: '/autologin',
    component: lazy(() => import('./components/Autologin'))
  },
  // 어플 전용
  

  {
    path: '/app_message',
    component: lazy(() => import('./app_components/Message_app')),
    layout: LayoutBlank
  },{
    path: '/login_message',
    component: lazy(() => import('./app_components/Login_message')),
    layout: LayoutBlank
  },
  




  {
  path: '/app_mb_join',
  component: lazy(() => import('./app_components/Member_join_app')),
  layout: LayoutBlank
},{
  path: '/login_mb_join',
  component: lazy(() => import('./app_components/Login_member_join')),
  layout: LayoutBlank
},






,{
  path: '/app_em_list',
  component: lazy(() => import('./app_components/Em_list_app')),
  layout: LayoutBlank
},{
  path: '/login_em_list',
  component: lazy(() => import('./app_components/Login_em_list')),
  layout: LayoutBlank
}



,{
  path: '/app_em_join',
  component: lazy(() => import('./app_components/Em_join_app')),
  layout: LayoutBlank
},{
  path: '/login_em_join',
  component: lazy(() => import('./app_components/Login_em_join')),
  layout: LayoutBlank
}



,{
  path: '/app_em_working',
  component: lazy(() => import('./app_components/Em_working_app')),
  layout: LayoutBlank
},{
  path: '/login_em_working',
  component: lazy(() => import('./app_components/Login_em_working')),
  layout: LayoutBlank
}







,{
  path: '/app_em_calc',
  component: lazy(() => import('./app_components/Em_calc_app')),
  layout: LayoutBlank
},{
  path: '/login_em_calc',
  component: lazy(() => import('./app_components/Login_em_calc')),
  layout: LayoutBlank
}









,{
  path: '/app_inventory',
  component: lazy(() => import('./app_components/Inventory_list_app')),
  layout: LayoutBlank
},{
  path: '/login_inventory',
  component: lazy(() => import('./app_components/Login_inventory')),
  layout: LayoutBlank
}






,{
  path: '/app_point',
  component: lazy(() => import('./app_components/Point_list_app')),
  layout: LayoutBlank
},{
  path: '/login_point',
  component: lazy(() => import('./app_components/Login_point')),
  layout: LayoutBlank
}





,{
  path: '/app_prepoint',
  component: lazy(() => import('./app_components/Prepoint_list_app')),
  layout: LayoutBlank
},{
  path: '/login_prepoint',
  component: lazy(() => import('./app_components/Login_prepoint')),
  layout: LayoutBlank
}






,{
  path: '/app_pass',
  component: lazy(() => import('./app_components/Pass_list_app')),
  layout: LayoutBlank
},{
  path: '/login_pass',
  component: lazy(() => import('./app_components/Login_pass')),
  layout: LayoutBlank
}



,{
  path: '/login_app',
  component: lazy(() => import('./app_components/Login_app')),
  layout: LayoutBlank
}



]
