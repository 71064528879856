import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Navbar,
  Nav,
  Modal,
  Button,
  Card,
  ListGroup,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "../../vendor/libs/react-datepicker/react-datepicker.scss";
import moment from "moment";
import "moment/locale/ko";

import axios from "axios";
import CreatableSelect from "react-select/creatable";
import Notifications from "../../notification/Notifications";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import ToolkitProvider, {
  Search,
  ColumnToggle,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";

const headers = {
  "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
  Accept: "*/*",
};

const ReactSwal = withReactContent(
  Swal.mixin({
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn btn-primary btn-lg",
      cancelButton: "btn btn-default btn-lg",
      actions: "text-center",
    },
  })
);

class Reservation_modal extends Component {
  constructor(props) {
    super(props);
    this.isRTL = document.documentElement.getAttribute("dir") === "rtl";
    this.onDefaultModalClose = this.onDefaultModalClose.bind(this);
    this.onTopModalClose = this.onTopModalClose.bind(this);
    this.onSlideModalClose = this.onSlideModalClose.bind(this);
    this.onFillInModalClose = this.onFillInModalClose.bind(this);

    console.log(props);
    this.state = {
      close_check: false,
      mode: this.props.mode,
      defaultModalShow: true,
      defaultModalSize: null,
      topModalShow: this.props.mode_show,
      topModalSize: null,
      slideModalShow: false,
      fillInModalShow: false,
      fillInModalSize: null,

      modal_step: 0,
      product_select: null,

      //스텝2
      em_select: 0,
      em_no: null,
      estimated_time: 0,
      decide_em: null,
      start_time: null,
      end_time: null,

      alert_show: false,
      memo: null,

      // 회원데이터
      member_data: {
        id: 0,
        name: null,
        point: 0,
        prepayment: 0,
        last_date: 0,
      },
      user_id: "",
      notification_count: 0,
      notification_page_num: 5,
      notification_now_page: 0,
      notification_data: "",
    };

    this.modalSizes = [
      {
        title: "Extra large",
        value: "xl",
      },
    ];
  }

  componentDidMount() {
    console.log("92");
    console.log(this.props);
  }
  state_reset_all() {
    this.setState(
      {
        mode: "예약",
        save_mode: "예약",
        modal_step: 0,
        product_select: null,
        em_select: 0,
        em_no: null,
        estimated_time: 0,
        post_body: {
          user_grId: this.props.state.user_grId,
          userId: this.props.state.userId,
        },
        decide_em: null,
        start_time: null,
        end_time: null,
        alert_show: false,
        alert_show2: false,
        memo: null,
        member_data: {
          id: 0,
          name: null,
          point: 0,
          prepayment: 0,
          last_date: 0,
        },
      },
      () => {
        this.onTopModalClose();
      }
    );
  }

  change_modal_member(row) {
    let check = this.state.save_mode;
    if (check === undefined) {
      check = "예약";
    }

    console.log(check);
    this.setState({ member_data: row }, () => {
      this.setState({ mode: check });
    });
  }

  onDefaultModalClose() {
    this.setState({ defaultModalShow: false });
  }

  onTopModalClose() {
    this.setState({ topModalShow: false });

    //  this.props.throw_fun();
  }

  onSlideModalClose() {
    this.setState({ slideModalShow: false });
  }

  onFillInModalClose() {
    this.setState({ fillInModalShow: false });
  }
  reserVationClick() {
    this.setState({ topModalShow: true, topModalSize: "xl" });
  }
  data_set_em(data, data_em, memo) {
    if (data === null) {
      this.setState({ product_select: null });
    } else {
      if (data.length > 0) {
        this.setState({ product_select: data, em_no: data_em, memo: memo });

        for (let i = 0; i < data_em.length; i++) {
          if (this.props.start_time === undefined) {
            if (data_em[i].value === data[0].em_no) {
              this.setState({ em_select: data_em[i] });
            }
          } else {
            if (data_em[i].value === this.props.start_time.resourceId) {
              this.setState({ em_select: data_em[i] });
            }
          }
        }

        let est = 0;
        for (let i = 0; i < data.length; i++) {
          if (Number(data[i].est) > 0) {
            est = est + Number(data[i].est);
          }
        }
        if (est > 10) {
          if (this.props.start_time === undefined) {
            est = moment().add(est, "minutes").toDate();
            this.setState({ estimated_time: est });
          } else {
            est = moment(this.props.start_time.start)
              .add(est, "minutes")
              .toDate();
            this.setState({ estimated_time: est });
          }
        } else {
          if (this.props.start_time === undefined) {
            est = moment().add(60, "minutes").toDate();
            this.setState({ estimated_time: est });
          } else {
            est = moment(this.props.start_time.start)
              .add(60, "minutes")
              .toDate();
            this.setState({ estimated_time: est });
          }
        }
      }
    }
  }

  basicSweetAlertExample() {
    ReactSwal.fire({
      // title: '예약이 등록되었습니다.',
      text: "정상적으로 등록되었습니다",
    });
    setTimeout(() => {
      ReactSwal.close();
    }, 1800);
  }
  approve_reservation() {
    if (this.state.mode === "예약") {
      let post_body_copy = [
        this.props.state.id[0][0],
        this.state.product_select,
        this.state.decide_em,
        this.state.start_time,
        this.state.end_time,
        this.state.memo,
        this.state.member_data.id,
      ];
      console.log('251')
      console.log(post_body_copy)
      axios
        .post(
          "/ajax_an/product/register_reservation.php",
          { post_body_copy },
          { headers }
        )
        .then((result) => {});
    } else if (this.state.mode === "제품") {
      let post_body_copy = [
        this.props.state.id[0][0],
        this.state.product_select,
        this.state.decide_em,
        this.state.start_time,
        this.state.end_time,
        this.state.memo,
        this.state.member_data.id,
      ];
      axios
        .post(
          "/ajax_an/product/register_product.php",
          { post_body_copy },
          { headers }
        )
        .then((result) => {});
    }

    this.props.throw_fun();
  }

  modal_member_search() {
    return (
      <Modal
        className="modal-top"
        show={this.state.topModalShow}
        size={this.state.topModalSize}
        onHide={this.onTopModalClose}
      >
        <Modal_design_member
          post_body={this.props.state}
          mb_throw={(row) => {
            this.change_modal_member(row);
          }}
        ></Modal_design_member>
        <Modal.Footer>
          <Button
            variant="default"
            onClick={() => {
              let mb_reset = {
                id: 0,
                name: null,
                point: 0,
                prepayment: 0,
                last_date: 0,
              };
              this.setState({ member_data: mb_reset });
              this.state_reset_all();
            }}
          >
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  modal_sellproduct_open() {
    return (
      <Modal
        className="modal-top"
        show={this.state.topModalShow}
        size={this.state.topModalSize}
        onHide={this.onTopModalClose}
      >
        <Modal_design_product
          post_body={this.props.state}
          mb_throw={(row) => {
            this.change_modal_member(row);
          }}
        />
        <Modal.Footer>
          <Button
            variant="default"
            onClick={() => {
              this.state_reset_all();
            }}
          >
            닫기
          </Button>
          {this.state.member_data.id === 0 ? (
            <Button
              variant="primary"
              onClick={() => {
                this.setState(
                  { save_mode: this.state.mode, mode: "회원검색" },
                  () => {
                    this.setState({ topModalShow: true });
                  }
                );
              }}
            >
              회원검색{" "}
            </Button>
          ) : null}
          <Button
            variant="primary"
            onClick={() => {
              if (this.state.product_select === null) {
                //경고창
                this.setState({ alert_show: true });
              } else if (this.state.modal_step === 0) {
                this.setState({ modal_step: 1, alert_show: false });
              } else {
                this.onTopModalClose();
                this.approve_reservation();
                this.basicSweetAlertExample();
              }
            }}
          >
            {this.state.modal_step === 0 ? "다음 단계" : "예약 등록"}
          </Button>{" "}
        </Modal.Footer>
      </Modal>
    );
  }

  member_detail() {
    if (this.state.member_data.id === 0) {
      return;
    } else {
      return (
        <div className="callout callout-primary">
          <h5>{this.state.member_data.name}님</h5>
          <hr />
          <p>
            최종방문일은 {this.state.member_data.last_date}이며, 포인트는 총{" "}
            {this.state.member_data.point}포인트 입니다
          </p>
          {this.state.member_data.prepayment === 0 ? null : null}{" "}
          {/* 선불권 사용고객 함수 만들기 */}
        </div>
      );
    }
  }

  modal_reservation_content() {
    return (
      <>
        {this.state.alert_show === true ? (
          <Notifications
            content="상품이 선택되지 않았습니다"
            show={() => {
              this.setState({ alert_show: false });
            }}
          />
        ) : null}

        {this.state.alert_show2 === true ? (
          <Notifications
            content="선불권,이용권 구매시 고객은 필수 선택입니다. 회원을 선택해주세요"
            show={() => {
              this.setState({ alert_show2: false });
            }}
          />
        ) : null}
        {this.member_detail()}
        {this.state.modal_step === 0 && this.state.mode === "예약" ? (
          <Modal_design
            post_body={this.props.state}
            user_confirm={(data, data_em, memo) => {
              this.data_set_em(data, data_em, memo);
            }}
          />
        ) : null}

        {this.state.modal_step === 1 && this.state.mode === "예약" ? (
          <Modal_reservation_step2
            em_no={this.state.em_no}
            em_select={this.state.em_select}
            top_title={this.state.product_select}
            estimated_time={this.state.estimated_time}
            decide_em={(data) => {
              this.setState({ decide_em: data });
            }}
            decide_time={(start, end) => {
              this.setState({ start_time: moment(start).format("YYYY-MM-DD HH:mm:ss"), end_time: moment(end).format("YYYY-MM-DD HH:mm:ss") });
          

            }}
            decide_product={(price) => {
              this.setState({ product_select: price });
            }}
            start_time={this.props.start_time}
          />
        ) : null}
        {this.state.modal_step === 0 && this.state.mode === "제품" ? (
          <Modal_design_product
            post_body={this.props.state}
            user_confirm={(data, data_em, memo) => {
              this.data_set_em(data, data_em, memo);
            }}
          />
        ) : null}

        {this.state.modal_step === 1 && this.state.mode === "제품" ? (
          <Modal_product_step2
            em_no={this.state.em_no}
            em_select={this.state.em_select}
            top_title={this.state.product_select}
            estimated_time={this.state.estimated_time}
            decide_em={(data) => {
              this.setState({ decide_em: data });
            }}
            decide_time={(start, end) => {
              this.setState({ start_time: start, end_time: end });
            }}
            decide_product={(price) => {
              this.setState({ product_select: price });
            }}
          />
        ) : null}

        <Modal.Footer>
          <Button
            variant="default"
            onClick={() => {
              this.state_reset_all();
            }}
          >
            닫기
          </Button>
          {this.state.member_data.id === 0 ? (
            <Button
              variant="primary"
              onClick={() => {
                this.onTopModalClose();
                this.setState(
                  { save_mode: this.state.mode, mode: "회원검색" },
                  () => {
                    this.setState({ topModalShow: true });
                  }
                );
              }}
            >
              회원검색{" "}
            </Button>
          ) : null}
          <Button
            variant="primary"
            onClick={() => {
              if (this.state.product_select === null) {
                //경고창
                this.setState({ alert_show: true });
              } else if (this.state.modal_step === 0) {
                let check = true;
                for (let i = 0; i < this.state.product_select.length; i++) {
                  if (
                    this.state.product_select[i].division === "선불권" ||
                    this.state.product_select[i].division === "이용권"
                  ) {
                    if (this.state.member_data.id === 0) {
                      check = false;
                    }
                  }
                }
                if (check) {
                  this.setState({
                    modal_step: 1,
                    alert_show: false,
                    alert_show2: false,
                  });
                } else {
                  this.setState({ alert_show2: true });
                }
              } else {
                this.onTopModalClose();
                this.approve_reservation();
                this.basicSweetAlertExample();
              }
            }}
          >
            {this.state.modal_step === 0 ? "다음 단계" : "예약 등록"}
          </Button>{" "}
        </Modal.Footer>
      </>
    );
  }
  modal_reservation_open() {
    return (
      <Modal
        className="modal-top"
        show={this.state.topModalShow}
        size={this.state.topModalSize}
        onHide={this.onTopModalClose}
      >
        {this.modal_reservation_content()}
      </Modal>
    );
  }
  render() {
    return (
      <>
        {/* Modal template */}
        {this.state.mode === "예약" ? this.modal_reservation_open() : null}
        {this.state.mode === "회원검색" ? this.modal_member_search() : null}
        {this.state.mode === "제품" ? this.modal_reservation_open() : null}
      </>
    );
  }
}

const { SearchBar } = Search;
const { ToggleList } = ColumnToggle;
const { ExportCSVButton } = CSVExport;
let data2 = [];

class Modal_design_member extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: data2,
    };
  }
  componentDidMount() {
    let post_body = this.props.post_body.id[0];

    axios
      .post("/ajax_an/product/memberSearch.php", post_body, { headers })
      .then((result) => {
        let group_options_copy = [...result.data];
        group_options_arr = group_options_copy;
        this.setState({ data: group_options_arr });
      });
  }
  render() {
    const isIE10Mode = document["documentMode"] === 10;
    const columns = [
      {
        text: "번호",
        dataField: "id",
        sort: true,
      },
      {
        text: "이름",
        dataField: "name",
        sort: true,
      },
      {
        text: "연락처",
        dataField: "phone",
        sort: true,
      },
      {
        text: "노쇼",
        dataField: "noshow",
        sort: true,
      },
    ];

    const BarStyling = {
      width: "100%",
      background: "#F2F1F9",
      border: "none",
      padding: "0.5rem",
    };

    const tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        this.props.mb_throw(row);
      },
    };
    return (
      <>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title as="h5">
            {" "}
            회원검색 <br />{" "}
          </Modal.Title>{" "}
        </Modal.Header>
        <Modal.Body>
          <div>
            {" "}
            {!isIE10Mode && (
              <React.Fragment>
                <ToolkitProvider
                  keyField="id"
                  data={this.state.data}
                  columns={columns}
                  bootstrap4
                  search
                  exportCSV
                >
                  {(props) => (
                    <div>
                      <SearchBar
                        placeholder={"검색하세요."}
                        {...props.searchProps}
                        style={BarStyling}
                      />
                      <BootstrapTable
                        data-checkbox-header="false"
                        {...props.baseProps}
                        wrapperClasses="table-responsive"
                        pagination={paginationFactory()}
                        filter={filterFactory()}
                        rowEvents={tableRowEvents}
                      />
                    </div>
                  )}
                </ToolkitProvider>{" "}
              </React.Fragment>
            )}
          </div>
        </Modal.Body>
      </>
    );
  }
}

let options_arr = [];
let options = [];
let group_options_arr = [];
class Modal_design extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "reservation",
      mode_step: 1,
      em_no: this.props.post_body.id[1],
      em_select: null,
      disabled: false,
      product_sel: null,
      singleValue: options[0],
      group_options: group_options_arr,
      product_options: options_arr,
      memo: null,
    };
  }
  componentDidMount() {
    let post_body = this.props.post_body.id[0][0];
    let post_body_copy = { post_body, division: "예약" };

    axios
      .post("/ajax_an/product/group_load.php", post_body_copy, { headers })
      .then((result) => {
        let group_options_copy = [...result.data];
        group_options_arr = group_options_copy;
        this.setState({ group_options: group_options_arr });
      });
    axios
      .post("/ajax_an/product/product_load.php", post_body_copy, { headers })
      .then((result2) => {
        let options_copy = [...result2.data];
        options_arr = options_copy;
        this.setState({ product_options: options_arr });
      });
  }
  change_option_arr(division) {
    if (division === "group") {
    } else if (division === "product") {
    } else {
      if (division === "0") {
        this.setState({ product_options: options_arr });
      } else {
        let my_arr = [];
        for (let idx in options_arr) {
          if (division === options_arr[idx].gr_no) {
            my_arr.push(options_arr[idx]);
          }
        }
        if (my_arr.length === 0) {
          my_arr = {
            value: "0",
            label: "등록된 상품이 없습니다.",
          };
        }
        this.setState({ product_options: my_arr });
      }
    }
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            예약등록 <span className="font-weight-light"></span>
            <br />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>예약을 확인하시고 다음 버튼을 눌러주세요</Form.Label>
          <Card.Body>
            <Form.Label>그룹선택</Form.Label>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              onChange={(e) => {
                console.log(e.value);
                this.change_option_arr(e.value);
              }}
              options={this.state.group_options}
              defaultValue={this.state.group_options[0]}
              isClearable={true}
              isSearchable={false}
              isDisabled={this.state.disabled}
            />
            <br />
            <Form.Label>상품선택</Form.Label>
            <br />
            <CreatableSelect
              isMulti
              className="react-select"
              classNamePrefix="react-select"
              options={this.state.product_options}
              onChange={(e) => {
                if (e.length === 0) {
                  this.props.user_confirm(null, null, this.state.memo);
                } else {
                  this.setState({ product_sel: e });
                  this.props.user_confirm(e, this.state.em_no, this.state.memo);
                }
              }}
              isSearchable={false}
            />{" "}
            <br />
            <Form.Label>메모</Form.Label>{" "}
            <Form.Control
              as="textarea"
              rows="3"
              onChange={(e) => {
                this.setState({ memo: e.target.value });
                this.props.user_confirm(
                  this.state.product_sel,
                  this.state.em_no,
                  e.target.value
                );
              }}
            />
          </Card.Body>
        </Modal.Body>
      </>
    );
  }
}
class Modal_reservation_step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      top_price: this.props.top_title,
      total_price: 0,

      startDate: new Date(),
      endDate: this.props.estimated_time,
      em_no: this.props.em_no,
      em_select: this.props.em_select,
      // react-flatpickr state
      //

      date: null,
      time: null,
      datetime: null,
      full: null,
      range: null,
      inline: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);

    let a = 0;
  }

  componentDidMount() {
    console.log("666");
    console.log(this.props);

    this.price_total();
                               
    this.props.decide_em([this.state.em_select]);
    console.log("831 실행확인");
    this.props.decide_time(this.state.startDate, this.state.endDate);
    this.props.decide_product(this.state.top_price);

    if (this.props.start_time === undefined) {
    } else {
      console.log("실행함 672");
      this.setState({ startDate: this.props.start_time.start }, () => {
        this.props.decide_time(this.state.startDate, this.state.endDate);
      });
    }
  }
  comma(str) {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
  }
  price_total() {
    let total = 0;
    for (let i = 0; i < this.state.top_price.length; i++) {
      total = total + Number(this.state.top_price[i].price);
    }
    total = String(this.comma(total));
    this.setState({ total_price: total });
    this.props.decide_product(this.state.top_price);
  }
  get placement() {
    const isRTL = document.documentElement.getAttribute("dir") === "rtl";
    return isRTL ? "auto-end" : "auto-start";
  }
  handleChange(date) {
    console.log("실행 699");

    let est = 0;

    for (let i = 0; i < this.props.top_title.length; i++) {
      est = est + Number(this.props.top_title[i].est);
    }
    let end;

    if (est >= 30) {
      end = moment(date).add(est, "minutes").toDate();
    } else {
      end = moment(date).add(60, "minutes").toDate();
    }
    this.setState({ startDate: date, endDate: end }, () => {

    this.props.decide_time(date, this.state.endDate);

    });

  }
  handleChangeEnd(date) {
    this.setState({ endDate: date }, ()=>{
      this.props.decide_time(this.state.startDate, date);
    });
 
  }
  render() {
    return (
      <>
        <Card.Header as="h6">예약 시간 설정</Card.Header>
        <Card.Body>
          <Form.Group>
            <Row xs={2}>
              {this.state.top_price.map((a, i) => {
                return (
                  <Col key={a.value}>
                    <Form.Text>{a.label}</Form.Text>
                    <Form.Control
                      id={a.value}
                      type="number"
                      placeholder="가격"
                      defaultValue={a.price}
                      onChange={(e) => {
                        let copy_val = [...this.state.top_price];
                        copy_val[i].price = e.target.value;
                        this.setState({ top_price: copy_val });
                        this.price_total();
                      }}
                    />
                  </Col>
                );
              })}
              <Col xs={12}>
                {" "}
                <br />
                <Form.Text>합계 (원)</Form.Text>
                <Form.Control
                  type="text"
                  placeholder="가격"
                  value={this.state.total_price}
                  step={1000}
                  disabled="disabled"
                />
              </Col>
            </Row>{" "}
          </Form.Group>

          <Form.Label>담당자 선택</Form.Label>
          <CreatableSelect
            isMulti
            className="react-select"
            classNamePrefix="react-select"
            options={this.state.em_no}
            defaultValue={this.state.em_select}
            onChange={(e) => {
              this.props.decide_em(e);
              console.log("937");
              console.log(e);
            }}
            isSearchable={false} 
          />
          <br />
          <Form.Label>예약일</Form.Label>
          <Row>
            {" "}
            <Col xs={6}>
              <DatePicker
                className="form-control"
                selected={this.state.startDate}
                onChange={this.handleChange}
                popperPlacement={this.placement}
                locale={ko}
                customInput={<CustomPicker />}
              />{" "}
            </Col>{" "}
            <Col xs={6}>
              <DatePicker
                className="form-control"
                selected={this.state.startDate}
                onChange={this.handleChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                dateFormat="h:mm aa"
                timeCaption="Time"
                popperPlacement={this.placement}
                locale={ko}
                customInput={<CustomPicker />}
              />{" "}
            </Col>
          </Row>
          <br />
          <Form.Label>종료일</Form.Label>
          <Row>
            <Col xs={6}>
              <DatePicker
                className="form-control"
                selected={this.state.startDate}
                onChange={this.handleChangeEnd}
                popperPlacement={this.placement}
                locale={ko}
                customInput={<CustomPicker />}
              />
            </Col>{" "}
            <Col xs={6}>
              <DatePicker
                className="form-control"
                selected={this.state.endDate}
                onChange={this.handleChangeEnd}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                dateFormat="h:mm aa"
                timeCaption="Time"
                popperPlacement={this.placement}
                locale={ko}
                customInput={<CustomPicker />}
              />{" "}
            </Col>
          </Row>
        </Card.Body>
      </>
    );
  }
}

class CustomPicker extends Component {
  render() {
    return (
      <Button variant="default" onClick={this.props.onClick} style={{width : '100%'}}>
        {this.props.value}
      </Button>
    )
  }
}


class Modal_design_product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "reservation",
      mode_step: 1,
      em_no: this.props.post_body.id[1],
      em_select: null,
      disabled: false,
      product_sel: null,
      singleValue: options[0],
      division: "제품",
      group_options: group_options_arr,
      product_options: options_arr,
      memo: null,
    };
  }

  load_arr() {
    let post_body = this.props.post_body.id[0][0];
    let post_body_copy = { post_body, division: this.state.division };

    axios
      .post("/ajax_an/product/group_load.php", post_body_copy, { headers })
      .then((result) => {
        let group_options_copy = [...result.data];
        group_options_arr = group_options_copy;
        this.setState({ group_options: group_options_arr });
      });
    axios
      .post("/ajax_an/product/product_load.php", post_body_copy, { headers })
      .then((result2) => {
        let options_copy = [...result2.data];
        options_arr = options_copy;
        console.log(options_arr);
        this.setState({ product_options: options_arr });
      });
  }
  componentDidMount() {
    this.load_arr();
  }
  change_option_arr(division) {
    // console.log(division)
    if (division === "group") {
    } else if (division === "product") {
    } else {
      if (division === "0") {
        this.setState({ product_options: options_arr });
      } else {
        let my_arr = [];
        for (let idx in options_arr) {
          console.log(options_arr[idx]);
          console.log(division);
          if (division === options_arr[idx].gr_no) {
            my_arr.push(options_arr[idx]);
          }
        }
        if (my_arr.length === 0) {
          my_arr = {
            value: "0",
            label: "등록된 상품이 없습니다.",
          };
        }
        this.setState({ product_options: my_arr });
      }
    }
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            상품판매 <span className="font-weight-light"></span>
            <br />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>판매할 상품을 선택하세요.</Form.Label>
          <Card.Body>
            <Form.Check
              custom
              inline
              type="radio"
              name="custom-radios"
              id="radios-1"
              checked={this.state.division === "제품"}
              label="제품판매"
              onChange={(e) => {
                this.setState({ division: "제품" }, () => {
                  this.load_arr();
                });
              }}
            />
            <Form.Check
              custom
              inline
              type="radio"
              name="custom-radios"
              id="radios-2"
              checked={this.state.division === "선불권"}
              label="선불권"
              onChange={(e) => {
                this.setState({ division: "선불권" }, () => {
                  this.load_arr();
                });
              }}
            />
            <Form.Check
              custom
              inline
              type="radio"
              name="custom-radios"
              id="radios-3"
              checked={this.state.division === "이용권"}
              label="이용권"
              onChange={(e) => {
                this.setState({ division: "이용권" }, () => {
                  this.load_arr();
                });
              }}
            />
            <br />
            <Form.Label>그룹선택</Form.Label>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              onChange={(e) => {
                this.change_option_arr(e.value);
              }}
              options={this.state.group_options}
              defaultValue={this.state.group_options[0]}
              isClearable={true}
              isSearchable={false}
              isDisabled={this.state.disabled}
            />
            <br />
            <Form.Label>상품선택</Form.Label>
            <br />
            <CreatableSelect
              isMulti
              className="react-select"
              classNamePrefix="react-select"
              options={this.state.product_options}
              isSearchable={false}
              onChange={(e) => {
                if (e.length === 0) {
                  this.props.user_confirm(null, null, this.state.memo);
                } else {
                  console.log(e);
                  this.setState({ product_sel: e });
                  this.props.user_confirm(e, this.state.em_no, this.state.memo);
                }
              }}
            />{" "}
            <br />
            <Form.Label>메모</Form.Label>{" "}
            <Form.Control
              as="textarea"
              rows="3"
              onChange={(e) => {
                this.setState({ memo: e.target.value });
                this.props.user_confirm(
                  this.state.product_sel,
                  this.state.em_no,
                  e.target.value
                );
              }}
            />
          </Card.Body>
        </Modal.Body>
      </>
    );
  }
}

class Modal_product_step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      top_price: this.props.top_title,
      total_price: 0,

      startDate: new Date(),
      endDate: this.props.estimated_time,
      em_no: this.props.em_no,
      em_select: this.props.em_select,
      // react-flatpickr state
      //

      date: null,
      time: null,
      datetime: null,
      full: null,
      range: null,
      inline: null,
    };
    console.log(this.props.top_title);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);

    let a = 0;
  }

  componentDidMount() {
    console.log('1222')
    console.log(this.state.em_select)
    console.log(this.state.em_no)
    this.price_total();
    this.props.decide_em(this.state.em_select);
    this.props.decide_time(this.state.startDate, this.state.endDate);
    this.props.decide_product(this.state.top_price);
  }
  comma(str) {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
  }
  price_total() {
    let total = 0;
    for (let i = 0; i < this.state.top_price.length; i++) {
      total =
        total +
        Number(this.state.top_price[i].price) *
          Number(this.state.top_price[i].amount);
    }
    total = String(this.comma(total));
    this.setState({ total_price: total });
    this.props.decide_product(this.state.top_price);
  }
  get placement() {
    const isRTL = document.documentElement.getAttribute("dir") === "rtl";
    return isRTL ? "auto-end" : "auto-start";
  }
  handleChange(date) {
    this.setState({ startDate: date });
    this.props.decide_time(date, this.state.endDate);
  }
  handleChangeEnd(date) {
    this.setState({ endDate: date });
    this.props.decide_time(this.state.startDate, date);
  }
  render() {
    return (
      <>
        <Card.Header as="h6">예약 시간 설정</Card.Header>
        <Card.Body>
          <Form.Group>
            <Row xs={2}>
              {this.state.top_price.map((a, i) => {
                return (
                  <>
                    <Col key={a.value}>
                      <Form.Text>{a.label}</Form.Text>
                      <Form.Control
                        id={a.value}
                        type="number"
                        placeholder="가격"
                        defaultValue={a.price}
                        onChange={(e) => {
                          let copy_val = [...this.state.top_price];
                          copy_val[i].price = e.target.value;
                          this.setState({ top_price: copy_val });
                          this.price_total();
                        }}
                      />
                    </Col>

                    <Col key={a.value + i}>
                      <Form.Text>수량</Form.Text>
                      <Form.Control
                        type="number"
                        disabled={a.division !== "제품"}
                        placeholder="수량"
                        id={a.amount}
                        defaultValue={a.amount}
                        onChange={(e) => {
                          let copy_val = [...this.state.top_price];
                          copy_val[i].amount = e.target.value;
                          this.setState({ top_price: copy_val });
                          this.price_total();
                        }}
                      />
                    </Col>
                  </>
                );
              })}
              <Col xs={12}>
                {" "}
                <br />
                <Form.Text>합계 (원)</Form.Text>
                <Form.Control
                  type="text"
                  placeholder="가격"
                  value={this.state.total_price}
                  step={1000}
                  disabled="disabled"
                />
              </Col>
            </Row>{" "}
          </Form.Group>

          <Form.Label>담당자 선택</Form.Label>
          <CreatableSelect
            isMulti
            className="react-select"
            classNamePrefix="react-select"
            options={this.state.em_no}
            defaultValue={this.state.em_select}
            onChange={(e) => {
              this.props.decide_em(e);
            }}
          />
          <br />
          <Form.Label>판매일</Form.Label>
          <Row>
            {" "}
            <Col xs={6}>
              <DatePicker
                className="form-control"
                selected={this.state.startDate}
                onChange={this.handleChange}
                popperPlacement={this.placement}
                locale={ko}
              />{" "}
            </Col>{" "}
            <Col xs={6}>
              <DatePicker
                className="form-control"
                selected={this.state.startDate}
                onChange={this.handleChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                dateFormat="h:mm aa"
                timeCaption="Time"
                popperPlacement={this.placement}
                locale={ko}
              />{" "}
            </Col>
          </Row>
          <br />
        </Card.Body>
      </>
    );
  }
}

function stats_props(state) {
  return {
    state: state.reducer2,
    payment_count: state.payment_count,
  };
}

export default connect(stats_props)(Reservation_modal);
