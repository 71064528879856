import { combineReducers } from 'redux'
import theme from './themeStore'
let post_body = [];
let payment_check = [{count : 0}];
let payment_list = [];

function reducer2(state = post_body, action) {
  if(action.type === "em_set"){

    let copy = action.payload;
    console.log("타입");

    return copy

  }else{
    console.log("널");
    return state
  }
  }

  
function payment_count(state = payment_check, action) {
  console.log(action.pa + "asdasd")
  if(action.type === "payment_check"){
 
    let copy = [...payment_check]; 
    copy = action.payload;     
    return copy

  }else{
    console.log("널");
    return payment_check
  }
  }
  
  
  function payment_load_list(state = payment_list, action) {

    if(action.type === "update"){
   
      let copy = [...payment_list]; 
      copy = action.payload;   
      console.log("Asdasdasd")  
      return copy
  
    }else{
      console.log("Asdasdasd")  
      return payment_list
    }
    }
    

export default combineReducers({ theme, reducer2, payment_count, payment_load_list})
