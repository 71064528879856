import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import "../vendor/styles/pages/authentication.scss";

class Loading extends Component {
  constructor(props) {
    super(props);
   console.log(props)
  }

  render() {

    const text = this.props.text;
    

    return (
  <div style={{display:'flex', position : 'fixed',height : '100%', width : '100%', background : '#fff' }}>

<div style={{margin : 'auto', textAlign : 'center'}}>
<Spinner animation="border" variant="primary" />
<br />
<br />
<div style={{ }}>{text}</div>
</div></div>)
  
  }
}



export default Loading;
