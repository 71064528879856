import React, { Component } from 'react'


import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Navbar, Nav, Modal, Button, ListGroup, Row, Col, Dropdown} from 'react-bootstrap'

import layoutHelpers from './helpers'

import Loading from '../../components/Loading'

import '../../vendor/libs/react-datepicker/react-datepicker.scss'

import 'moment/locale/ko'
import { NavLink as RouterLink } from "react-router-dom"
import axios from 'axios';

import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

import Reservation_modal from './Reservation_modal.js'


let reservation_Confirm = [];
let reservation_count = 0;

const menu_title = ['캘린더', '매출승인', '회원정보','회원등록', '회원미수금', '갤러리', 'SMS문자', '직원정보', '직원등록','출퇴근 기록', '출퇴근 계산기', '제품 내역',
'포인트 내역', '선불권 내역', '이용권 내역', '기간별 매출', '직원별 매출'];
const menu_link = ['/calendar', '/payment-history', '/member-list','/member-join','/member-unpaid','/gallery-box', '/sms', '/em-list', '/em-join','/em-working', '/em-calc', 
'/inventory-history', '/point-history', '/prepoint-history', '/pass-history', '/stat-period', '/em_period'];

const headers = {
  'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
  'Accept': '*/*'};

const ReactSwal = withReactContent(Swal.mixin({
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-primary btn-lg',
    cancelButton: 'btn btn-default btn-lg',
    actions: 'text-center'
  }
}))

class LayoutNavbar extends Component {
  constructor(props) {
    super(props)
    this.isRTL = document.documentElement.getAttribute('dir') === 'rtl'
    this.onDefaultModalClose = this.onDefaultModalClose.bind(this)
    this.onTopModalClose = this.onTopModalClose.bind(this)
    this.onSlideModalClose = this.onSlideModalClose.bind(this)
    this.onFillInModalClose = this.onFillInModalClose.bind(this)
   
    this.state = {
      post_body: [[],[]],
      loding_check : false,
      mode : '',
      defaultModalShow: false,
      defaultModalSize: null,
      topModalShow: false,
      topModalSize: null,
      slideModalShow: false,
      fillInModalShow: false,
      fillInModalSize: null,
      reservation_count:0,
      
      modal_step : 0,
      product_select : null,
      
      //스텝2
      em_select : 0,
      em_no : null,
      estimated_time : 0,
      decide_em : null,
      start_time : null,
      end_time : null,

      alert_show : false,
      memo : null,

      // 회원데이터
      member_data : {
        id:0, name:null, point:0, prepayment:0, last_date :0
      },
      user_id: "",
      notification_count : 0,
      notification_page_num : 5,
      notification_now_page : 0,
      notification_data : "",  
    }

    this.modalSizes = [{
      title: 'Extra large',
      value: 'xl'
    }];
}
menu_load(){
  let data = [];
  for(let i=0;i<15;i++){
    let check = localStorage.getItem('menu_box'+i.toString());
    if(check === "true"){
      data.push(<RouterLink to={menu_link[i]} className="nav-link">{menu_title[i]}</RouterLink>)
    }else{
      
    }
    
  }
  return data;

}

  componentDidMount(){


 
/*     
테스트시엔 이걸로 실서버에선 세션체크


    if(this.props.state.length === 0){     // 회원데이터 스토리지로 넘김 데이터가 있다면 실행안함
      axios.post('https://aann.co.kr/ajax_an/test.php').then((result) => {
     
      this.props.dispatch({ type: "em_set", payload:{ id: result.data} }, ()=>{
        this.setState({user_id : this.props.state.id[0][0].gr_Id})
      });
   
      });  }else{ }
 */

  

axios.get('/ajax_an/login_check.php').then((result) =>{ 
  console.log('129')
  console.log(result.data)
  if(result.data === "no"){
    console.log("no")
    const { history } = this.props;

      history.push("/");   
  
  }else{
    this.setState({post_body : result.data,  loding_check : true}, ()=>{ 
    this.menu_load();
    this.notification_count();
    })
    this.props.dispatch({ type: "em_set", payload:{ id: result.data} });
  }

}).catch(function(error) {
  console.log(error);
});

    //  this.menu_load();
      
   /*

       if(this.props.state.length === 0){     // 회원데이터 스토리지로 넘김 데이터가 있다면 실행안함
        axios.post('https://aann.co.kr/ajax_an/test.php').then((result) => {
       
        this.props.dispatch({ type: "em_set", payload:{ id: result.data} }, ()=>{
          this.setState({user_id : this.props.state.id[0][0].gr_Id}, ()=>{

          })
        });
     
        });  }else{ }


*/
     



     
  }

  notification_count(){

    let post_body = [];
    post_body.push(this.state.post_body[0][0]);
    axios.post('/ajax_an/notice/notification_count.php', post_body, {headers}).then((result) => {
      if(result.data === 0){
          // 알림 없음
    
      }else{
        this.setState({notification_count : result.data});
      }
    }); 
    
  }

 state_reset_all(){
    this.setState({
      mode : '예약', save_mode : "예약", reservation_count:0, modal_step : 0,
      product_select : null, em_select : 0,
      em_no : null,estimated_time : 0,
      decide_em : null, start_time : null, end_time : null,
      alert_show : false, alert_show2 : false, memo : null,
      member_data : {
        id:0, name:null, point:0, prepayment:0, last_date :0
      }
    }, ()=>{this.onTopModalClose(); });
 }
  
change_modal_member(row){

  let check = this.state.save_mode;
  if(check === undefined){
    check = "예약";
  }
  
  console.log(check)
  this.setState({member_data : row }, ()=>{ this.setState({ mode : check});
    });
 }
  toggleSidenav(e) {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }
  onDefaultModalClose() {
    this.setState({ defaultModalShow: false })
  }

  onTopModalClose() {
    this.setState({ topModalShow: false })
  }

  onSlideModalClose() {
    this.setState({ slideModalShow: false })
  }

  onFillInModalClose() {
    this.setState({ fillInModalShow: false })
  }
  reserVationClick(){
    this.setState({  topModalShow: true, topModalSize: 'xl' })

  }
 

  basicSweetAlertExample() {
    ReactSwal.fire({
     // title: '예약이 등록되었습니다.',
      text: '정상적으로 등록되었습니다',
    })
    setTimeout(()=> {  ReactSwal.close()

 
    }, 1800);
  }
  approve_reservation(){
  if(this.state.mode === "예약"){
   

    let post_body_copy = [this.state.post_body[0][0],this.state.product_select, this.state.decide_em, this.state.start_time, this.state.end_time, this.state.memo, this.state.member_data.id];
    axios.post('/ajax_an/product/register_reservation.php', {post_body_copy}, {headers}).then((result) => {
    });

  }else if(this.state.mode ==="제품"){
    
    let post_body_copy = [this.state.post_body[0][0],this.state.product_select, this.state.decide_em, this.state.start_time, this.state.end_time, this.state.memo, this.state.member_data.id];
    axios.post('/ajax_an/product/register_product.php', {post_body_copy}, {headers}).then((result) => {
    });
  }
/*
  let post_body = this.props.state.id[0];
 
   axios.post('http://aann.co.kr/ajax_an/payment/list_load_count.php', post_body, {headers}).then((result) => {
     this.props.dispatch({ type: "payment_check", payload:{ count: result.data} });
 
  });  
*/

var link =  document.location.href;
var substring = "payment-history"
var payment = link.includes(substring);
if(payment === true){
  console.log("있")

  const { history } = this.props;

  setTimeout(()=> {  ReactSwal.close()
 //  history.replace("/payment-history");
 //   history.push("/payment-history");

 history.push({ pathname: substring+" "});
 history.push({ pathname: substring});
  }, 1000);




}else{
  console.log("없")
}

  }

modal_notification_open(){


  return(
    <Modal className="modal-top" show={this.state.topModalShow} size={this.state.topModalSize} onHide={this.onTopModalClose}>
<Modal.Header> <Modal.Title as="h5"> 알림 <br />  </Modal.Title> </Modal.Header><Modal.Body>


{this.state.notification_data.map((a, i)=>{
  return(
  <Row className="align-items-center no-gutters" style={{marginBottom : "10px", 'borderBottom': '1px solid #eee','paddingBottom': '10px'}}>
  <Col xs={10}>  <div className="text-big">{a.content}</div>
<div className="text-muted small mt-1 dotum_font">{a.registration_date} &nbsp;·&nbsp; <a href="#d" className="text-muted">{a.division}</a></div>   </Col>
<Col xs={2}><button type="button" className="close" onClick={()=>{
  console.log(a.no)
  this.del_notification(a.no);
}}><span aria-hidden="true" style={{'verticalAlign': 'super'}}>×</span><span className="sr-only" >Close</span></button></Col>

</Row>

)
})}


{
  this.state.notification_count > this.state.notification_now_page+this.state.notification_page_num
                ? <ListGroup.Item className="text-center" onClick={
                  ()=>{
                      let page = this.state.notification_now_page + this.state.notification_page_num;
          
                      this.setState({notification_now_page : page}, ()=>{
                        console.log(page)
                        let post_body = [];
                         post_body.push(this.state.post_body[0][0]);
                        post_body.push({now_page : this.state.notification_now_page, page_num : this.state.notification_page_num});
                        axios.post('/ajax_an/notice/notification_list.php', post_body, {headers}).then((result) => {
                          let data = this.state.notification_data;
                           data.push(...result.data);
                           console.log(this.state.notification_data)

                            this.setState({notification_data : data}, ()=>{
                        
                            })
                           
                        });  

                      });
                  }
                }>
                    더보기
                  </ListGroup.Item>
                :null
  
              }


</Modal.Body>
           <Modal.Footer>                                                           
          <Button variant="default" onClick={this.onTopModalClose}>닫기</Button>
              </Modal.Footer>
                  </Modal>)
 
 }

del_notification(e){
  let index = this.state.notification_data.findIndex(x => x.no === e);

   let data = this.state.notification_data;
   data.splice(index, 1);
   this.setState({notification_data : data});


   let post_body = []
   post_body.push(this.state.post_body[0][0]);
   post_body.push({no : e});
 
   axios.post('/ajax_an/notice/notification_delete.php', post_body, {headers}).then((result) => {
        console.log(result.data)
   }); 

}


   render() {

    if(this.state.loding_check === false){
      return <Loading text="유저 정보를 불러오고 있습니다."/>
    }

  return (
      <>

      
     <Navbar bg="white" expand="lg">
  
            <Navbar.Brand href="#brand-6" onClick={this.prevent}></Navbar.Brand>
            {
                this.props.sidenavToggle && (<Nav className="align-items-lg-center mr-auto mr-lg-4">
                    <Nav.Item as="a" className="nav-item nav-link px-0 ml-2 ml-lg-0" href="#toggle"
                        onClick={
                    
                            this.toggleSidenav
                       
                    }>
                        <i className="ion ion-md-menu text-large align-middle"></i>
                    </Nav.Item>
                </Nav>)
            }
            <Navbar.Toggle />
            
            <Navbar.Collapse>
              <Nav className="mr-auto noto_font">
                <Nav.Link onClick={
                  ()=>{
                    this.setState({mode : ""},()=>{ this.setState({mode : "회원검색"});});    
                
                                    }
                }>회원검색{this.state.save_mode === '제품'?
              <div style={{ 'display': 'inline-block','fontSize': '0.7rem'}}>&nbsp;&nbsp;(상품판매)</div>
            :null}</Nav.Link>
                <Nav.Link onClick={
                ()=>{ 
            //   this.setState({topModalShow : true, mode : "예약"});
       
            this.setState({mode : ""},()=>{ this.setState({mode : "예약"});});    
            //   this.reserVationClick()
                 }
                }>예약등록</Nav.Link>
                <Nav.Link  onClick={()=>{
                  this.setState({mode : ""},()=>{ this.setState({mode : "제품"});});   
              

                }}>상품판매</Nav.Link>

                {this.menu_load()}

                 </Nav>

                 <Nav className="align-items-lg-center ml-auto">

                 <div className="demo-navbar-notifications mr-lg-3 dropdown nav-item noto_font" onClick={()=>{

                   let post_body = []
                   post_body.push(this.state.post_body[0][0]);
                 
                   let data_array = [];
                   let data = [];
                 
                   axios.post('/ajax_an/notice/notification_count.php', post_body, {headers}).then((result) => {
                           if(result.data === 0){
                               // 알림 없음
                 
                           }else{
                             post_body.push({now_page : 0, page_num : this.state.notification_page_num});
                             axios.post('/ajax_an/notice/notification_list.php', post_body, {headers}).then((result2) => {
                                 this.setState({notification_data : result2.data, notification_count : result.data}, ()=>{
                                  this.setState({topModalShow : true, mode : "알림", notification_now_page : 0});
                                 })
                                
                             });  
                 
                           }
                   }); 

                }}>
                  <a aria-haspopup="true" aria-expanded="false" href="#" className="hide-arrow dropdown-toggle nav-link" role="button">
                    <i className="ion ion-md-notifications-outline navbar-icon align-middle"></i>
                    {this.state.notification_count > 0
                    ? <span className="badge badge-primary badge-dot indicator"></span>
                    :null}
                   
                    <span className="d-lg-none align-middle">&nbsp; 알림</span></a></div>
                    <div className="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>




              <span className="navbar-text">
              <Dropdown as={Nav.Item} className="demo-navbar-user" alignRight={!this.isRTL}>
           <Dropdown.Toggle as={Nav.Link}>
           <span className="px-1 mr-lg-2 ml-2 ml-lg-0">내정보</span>
            
           </Dropdown.Toggle>

           <Dropdown.Menu>
             <Dropdown.Item hred="/mypage" onClick={()=>{  
               const { history } = this.props;
                 history.push('/mypage'); }}><i className="ion ion-ios-person text-lightest"></i> &nbsp; 프로필</Dropdown.Item>
             <Dropdown.Item hred="#" onClick={()=>{  
               const { history } = this.props;
                 history.push('/msg'); }}><i className="ion ion-ios-mail text-lightest"></i> &nbsp; 쪽지함</Dropdown.Item>
             <Dropdown.Item hred="#" onClick={()=>{  
               const { history } = this.props;
                 history.push('/autologin'); }}><i className="ion ion-md-settings text-lightest"></i> &nbsp; 보안설정</Dropdown.Item>
             <Dropdown.Divider />
             <Dropdown.Item onClick={()=>{
               axios.post('/ajax_an/logout.php').then((result) =>{ 
                 const { history } = this.props;
                 history.push('/');

                });
             }}><i className="ion ion-ios-log-out text-danger"></i> &nbsp; 로그아웃</Dropdown.Item>
           </Dropdown.Menu>
         </Dropdown>
              </span>
 

              </Nav>

                </Navbar.Collapse>
                </Navbar>

                
            {/* Modal template */}
            {this.state.mode === '예약' ? <Reservation_modal mode="예약" mode_show={true} throw_fun={()=>{
            this.setState({mode : ""});

            
var link =  document.location.href;
 var lastItem = link.split("/").pop();
  console.log(lastItem)
  const { history } = this.props;

  setTimeout(()=> {  ReactSwal.close()
    history.push("/"+lastItem+" ");   
    history.push("/"+lastItem);   
  }, 1000);


            }}/> :null}
             {this.state.mode === '회원검색' ? <Reservation_modal mode="회원검색" mode_show={true} throw_fun={()=>{
            this.setState({mode : ""});
            
            
            
            }}/> :null}
             {this.state.mode === '제품' ?<Reservation_modal mode="제품" mode_show={true} throw_fun={()=>{
            this.setState({mode : ""});

            var link =  document.location.href;
            var lastItem = link.split("/").pop();
             console.log(lastItem)
             const { history } = this.props;
           
             setTimeout(()=> {  ReactSwal.close()
               history.push("/"+lastItem+" ");   
               history.push("/"+lastItem);   
             }, 1000);

             
            }}/> :null}
             {this.state.mode === '알림' ? this.modal_notification_open() :null}

      </>
    )
  }
}

LayoutNavbar.propTypes = {
  sidenavToggle: PropTypes.bool
}

LayoutNavbar.defaultProps = {
  sidenavToggle: true
}



function stats_props(state){

  return{
    state:state.reducer2,
    payment_count : state.payment_count
  }
}


export default connect(stats_props)(LayoutNavbar)

