import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Sidenav from '../../vendor/libs/sidenav'
import layoutHelpers from './helpers'



class LayoutSidenav extends Component {
  layoutSidenavClasses() {
    let bg = this.props.sidenavBg

    if (this.props.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '')
    }

    return `bg-${bg} ` + (
      this.props.orientation !== 'horizontal'
        ? 'layout-sidenav menu_css'
        : 'layout-sidenav-horizontal container-p-x flex-grow-0'
    )
  }

  toggleSidenav(e) {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  isMenuActive(url) {
    return this.props.location.pathname.indexOf(url) === 0
  }

  isMenuOpen(url) {
    return this.props.location.pathname.indexOf(url) === 0 && this.props.orientation !== 'horizontal'
  }

  render() {
    return (
      <Sidenav orientation={this.props.orientation} className={this.layoutSidenavClasses()}>
        {/* Inner */}
        <div className={`sidenav-inner ${this.props.orientation !== 'horizontal' ? 'py-1' : ''}`}>
        <br />
        
        <Sidenav.RouterLink  to="/payment-history"  className="menu_tab_text" >ANAN_SHOP</Sidenav.RouterLink><br />
        <div className="sidenav-divider mt-0"></div>

        <Sidenav.Menu icon="sidenav-icon ion ion-md-paper" linkText="예약관리">
              <Sidenav.RouterLink  to="/calendar" className="menu_tab_text" >캘린더</Sidenav.RouterLink>
              <Sidenav.RouterLink  to="/payment-history"  className="menu_tab_text" >매출승인</Sidenav.RouterLink>
              
            </Sidenav.Menu>

            <Sidenav.Menu icon="ion ion-ios-contact" linkText="회원관리">
              <Sidenav.RouterLink to="/member-list" className="menu_tab_text">회원정보</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/member-join" className="menu_tab_text">회원등록</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/member-unpaid" className="menu_tab_text">회원미수금</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/gallery-box" className="menu_tab_text" >갤러리</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/sms" className="menu_tab_text" >SMS문자</Sidenav.RouterLink>
            </Sidenav.Menu>
            
            <Sidenav.Menu icon="sidenav-icon ion ion-ios-heart" linkText="직원메뉴">
              <Sidenav.RouterLink to="/em-list" className="menu_tab_text" >직원정보</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/em-join" className="menu_tab_text" >직원등록</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/em-working" className="menu_tab_text">출퇴근 기록</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/em-calc" className="menu_tab_text">출퇴근 계산기</Sidenav.RouterLink>
            </Sidenav.Menu>

            <Sidenav.Menu icon="sidenav-icon ion ion-md-document" linkText="상품관리">
              <Sidenav.RouterLink to="/inventory-history" className="menu_tab_text" onClick={this.prevent}>제품 내역</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/point-history" className="menu_tab_text">포인트 내역</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/prepoint-history" className="menu_tab_text" onClick={this.prevent}>선불권 내역</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/pass-history" className="menu_tab_text" onClick={this.prevent}>이용권 내역</Sidenav.RouterLink>

            </Sidenav.Menu>
            <Sidenav.Menu icon="ion ion-ios-contact" linkText="매출통계">
            <Sidenav.RouterLink to="/stat-period" className="menu_tab_text" onClick={this.prevent}>기간별 매출</Sidenav.RouterLink>
            <Sidenav.RouterLink to="/em-period" className="menu_tab_text" onClick={this.prevent}>직원별 매출</Sidenav.RouterLink>
            </Sidenav.Menu>

            <Sidenav.Menu  icon="ion ion-md-desktop" linkText="등록설정">
              <Sidenav.RouterLink to="/set_reservation" className="menu_tab_text">예약설정</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/set_product" className="menu_tab_text">제품설정</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/set_prepayment" className="menu_tab_text">선불권설정</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/set_ticket" className="menu_tab_text">이용권설정</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/member_set" className="menu_tab_text">회원등급설정</Sidenav.RouterLink>
          </Sidenav.Menu>
          <br />
          <div className="sidenav-divider mt-0"></div>
        </div>

      </Sidenav>
    )
  }
}

LayoutSidenav.propTypes = {
  orientation: PropTypes.oneOf(['vertical', 'horizontal'])
}

LayoutSidenav.defaultProps = {
  orientation: 'vertical'
}

export default connect(store => ({
  sidenavBg: store.theme.sidenavBg
}))(LayoutSidenav)
